import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon } from 'antd';
import moment from 'moment';

class InvoiceDataTable extends React.Component {
  render() {
    const { data, isLoading } = this.props;

    return (
      <React.Fragment>
        <Table
          title={() => (
            <span className='title'>
              <Icon type='snippets' />
              &nbsp;
              {'List of Invoice'}
            </span>
          )}
          align='left'
          bordered
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: 'nowrap' }}
          loading={isLoading}
          dataSource={data}
          rowKey={({ _id }) => _id.$oid}
        >
          <Table.Column title='No.' dataIndex='no' />
          <Table.Column title='Invoice Number' dataIndex='invoice_number' />
          <Table.Column title='Description' dataIndex='description' />
          <Table.Column title='Total Amount' dataIndex='total_amount' />
          <Table.Column
            title='Status'
            dataIndex='status'
            render={(status) => (
              <span style={{ textTransform: 'capitalize' }}>{status}</span>
            )}
          />
          <Table.Column
            title='Created On'
            dataIndex='created_on'
            render={(created_on) => (
              <span>
                {created_on
                  ? moment(created_on.$date).format('DD-MMM-YYYY hh:mm a')
                  : ''}
              </span>
            )}
          />
        </Table>
      </React.Fragment>
    );
  }
}

InvoiceDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InvoiceDataTable;
