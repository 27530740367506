import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Tooltip } from 'antd';

import { PermissionContext } from '../../permissionContext';

class MainMenu extends React.Component {
  static contextType = PermissionContext;

  state = {
    subMenuKey: '',
  };

  render() {
    const iconStyle = {
      fontSize: '24px',
      lineHeight: '64px',
    };
    const { checkSectionPermission, checkSubSectionPermission } = this.context;

    return (
      <Menu {...this.props} theme='dark' selectable={false}>
        {/* USER */}
        {checkSectionPermission('users') && (
          <Menu.SubMenu
            title={
              <Tooltip title='User' mouseEnterDelay={1} placement='top'>
                <Icon type='team' style={iconStyle} />
              </Tooltip>
            }
            key='user-menu'
          >
            {checkSubSectionPermission('users', 'list') && (
              <Menu.Item key='user-list'>
                <Link to='/users/list'>
                  <Icon type='ordered-list' />
                  List
                </Link>
              </Menu.Item>
            )}

            {checkSubSectionPermission('users', 'add') && (
              <Menu.Item key='user-add'>
                <Link to='/users/add'>
                  <Icon type='plus' />
                  Add
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {/* ORGANIZATION */}
        {checkSectionPermission('organizations') && (
          <Menu.SubMenu
            title={
              <Tooltip title='Organization' mouseEnterDelay={1} placement='top'>
                <Icon type='bank' style={iconStyle} />
              </Tooltip>
            }
            key='org-menu'
          >
            {checkSubSectionPermission('organizations', 'list') && (
              <Menu.Item key='org-list'>
                <Link to='/organizations/list'>
                  <Icon type='ordered-list' />
                  List
                </Link>
              </Menu.Item>
            )}

            {checkSubSectionPermission('organizations', 'add') && (
              <Menu.Item key='org-add'>
                <Link to='/organizations/add'>
                  <Icon type='plus' />
                  Add
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {/* ASSET */}
        {checkSectionPermission('assets') && (
          <Menu.SubMenu
            title={
              <Tooltip title='Asset' mouseEnterDelay={1} placement='top'>
                <Icon type='car' style={iconStyle} />
              </Tooltip>
            }
            key='asset-menu'
          >
            {checkSubSectionPermission('assets', 'list') && (
              <Menu.Item key='asset-list'>
                <Link to='/assets/list'>
                  <Icon type='ordered-list' />
                  List
                </Link>
              </Menu.Item>
            )}
            {checkSubSectionPermission('assets', 'add') && (
              <Menu.Item key='asset-add'>
                <Link to='/assets/add'>
                  <Icon type='plus' />
                  Add
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {/* DEVICES */}
        {checkSectionPermission('devices') && (
          <Menu.SubMenu
            title={
              <Tooltip title='Device' mouseEnterDelay={1} placement='top'>
                <Icon type='cluster' style={iconStyle} />
              </Tooltip>
            }
            key='device-menu'
          >
            {checkSubSectionPermission('devices', 'list') && (
              <Menu.Item key='device-list'>
                <Link to='/devices/list'>
                  <Icon type='ordered-list' />
                  List
                </Link>
              </Menu.Item>
            )}
            {checkSubSectionPermission('devices', 'add') && (
              <Menu.Item key='device-add'>
                <Link to='/devices/add'>
                  <Icon type='plus' />
                  Add
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {/* Payments */}
        {checkSectionPermission('payments') && (
          <Menu.SubMenu
            title={
              <Tooltip title='Payment' mouseEnterDelay={1} placement='top'>
                <Icon type='star' style={iconStyle} />
              </Tooltip>
            }
            key='payment-menu'
          >
            {checkSubSectionPermission('payments', 'reports') && (
              <Menu.Item key='reports'>
                <Link to='/payments/online-report'>
                  <Icon type='bar-chart' />
                  Report
                </Link>
              </Menu.Item>
            )}
            {checkSubSectionPermission('payments', 'bkash') && (
              <Menu.Item key='bkash'>
                <Link to='/payments/bkash'>
                  <Icon type='safety' />
                  bKash
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {/* STAFF */}
        {checkSectionPermission('staff') && (
          <Menu.SubMenu
            title={
              <Tooltip title='Staff User' mouseEnterDelay={1} placement='top'>
                <Icon type='solution' style={iconStyle} />
              </Tooltip>
            }
            key='staff-menu'
          >
            {checkSubSectionPermission('staff', 'list') && (
              <Menu.Item key='list'>
                <Link to='/staff/list'>
                  <Icon type='ordered-list' />
                  List
                </Link>
              </Menu.Item>
            )}
            {checkSubSectionPermission('staff', 'approve') && (
              <Menu.Item key='staff-approve'>
                <Link to='/staff/approve'>
                  <Icon type='plus' />
                  Approve
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {/* Bills */}
        {checkSectionPermission('bills') && (
          <Menu.SubMenu
            title={
              <Tooltip title='Bills' mouseEnterDelay={1} placement='top'>
                <Icon type='file-search' style={iconStyle} />
              </Tooltip>
            }
            key='staff-menu'
          >
            {checkSubSectionPermission('bills', 'view') && (
              <Menu.Item key='view'>
                <Link to='/bills/view'>
                  <Icon type='ordered-list' />
                  View
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
      </Menu>
    );
  }
}
export default MainMenu;
